import request from '@/utils/request'
import requestUrls from './url'

// 获取token
export function getTokenTest(query) {
  return request({
    url: requestUrls.manniuToken,
    method: 'get',
    params: query
  })
}

// 获取用户信息
export function getUserInfo(query) {
  return request({
    url: requestUrls.manniuUserInfo,
    method: 'post',
    data: query
  })
}

// 退出登录
export function loginoutTest(query) {
  return request({
    url: requestUrls.manniuLogout,
    method: 'post',
    data: query
  })
}

// test 接口
export function demoTime(query) {
  return request({
    url: requestUrls.demoTime,
    method: 'get',
    params: query
  })
}

// 从密钥服务获取RSA公钥
export function generateCipher(data) {
  return request({
    url: requestUrls.cipher,
    method: 'post',
    data
  })
}

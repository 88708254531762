import TRTCCalling from 'trtc-calling-js';

let options = {
  SDKAppID: 1400812577, // 接入时需要将0替换为您的云通信应用的 SDKAppID
};

const trtcCalling = new TRTCCalling(options);

// 4 无日志级别
trtcCalling.setLogLevel(4);

export default trtcCalling;

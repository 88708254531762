// import parseTime, formatTime and set to filter
import Vue from 'vue';
export { parseTime, formatTime } from '@/utils';

/**
 * 垫付大状态
 */
Vue.filter('filterCurrentAdvanceStatus', function (status) {
  const obj = {
    '00': '预约申请',
    '01': '预约审核',
    '02': '补传材料',
    '03': '垫款申请',
    '04': '保理申请',
    '05': '理赔审核',
    '06': '代扣发起',
    '07': '订单重开',
    '08': '已完结',
    '09': '已终止'
  };
  return obj[status] || '—';
});

/**
 * 影像资料tab
 */
Vue.filter('filterTabName', function (status) {
  const obj = {
    1: '身份资料',
    2: '预授权资料',
    3: '检查检验',
    4: '病历资料',
    5: '医嘱资料',
    6: '医疗费用',
    7: '理赔资料',
    8: '支付凭证'
  };
  return obj[status] || '—';
});

// 垫付进度颜色
Vue.filter('filterAdvanceStatus', function (status) {
  const obj = {
    '101': '待预约审核',
    '102': '预约审核中',
    '103': '预约成功',
    '104': '预约失败',
    '201': '待补传材料',
    '202': '补传完成',
    '301': '待垫款申请',
    '302': '同意服务',
    '303': '不同意服务',
    '304': '垫款申请中',
    '305': '垫款申请成功',
    '306': '垫款申请失败',
    '307': '垫款成功',
    '308': '垫款失败',
    '401': '待保理申请',
    '402': '保理申请中',
    '403': '保理成功',
    '404': '保理失败',
    '501': '待理赔提交',
    '502': '理赔审核中',
    '503': '理赔审核中',
    '504': '拒赔',
    '601': '待发起代扣',
    '602': '代扣中',
    '603': '代扣成功',
    '604': '代扣失败',
    '701': '重开申请成功',
    '702': '重开申请失败',
    '703': '重开成功',
    '704': '重开失败',
    '801': '已完结'
  };
  return obj[status] || '—';
});

/**
 * 垫付类型
 */
Vue.filter('filterAdvanceType', function (status) {
  const obj = {
    '01': '银行垫付',
    '02': '服务商垫付',
    '03': '银联垫付',
  };
  return obj[status] || '—';
});

// 导入状态
Vue.filter('filterImportList', function (status) {
  const obj = {
    '01': '导入成功',
    '04': '导入成功'
  };
  return obj[status] || '导入失败';
});
Vue.filter('hidePhone', function(status) {
  return `${status.substring(0, 3)}****${status.substring(status.length - 3)}`;
});
Vue.filter('hideIdCard', function(status) {
    return `${status.substring(0, 6)}****${status.length === 18 ? status.substring(status.length - 4) : status.substring(status.length - 3)}`;
});

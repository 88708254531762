import Vue from 'vue';
import Router from 'vue-router';
import http from '@/utils/request';
import { isURL } from '@/utils/validate';

Vue.use(Router);
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

// 页面路由(独立页面)
export const pageRoutes = [
  {
    path: '/404',
    component: () => import('@/views/pages/404'),
    name: '404',
    meta: { title: '404未找到' },
    beforeEnter(to, from, next) {
      // 拦截处理特殊业务场景
      // 如果, 重定向路由包含__双下划线, 为临时添加路由
      if (/__.*/.test(to.redirectedFrom)) {
        return next(to.redirectedFrom.replace(/__.*/, ''));
      }
      next();
    },
  },
  {
    path: '/login',
    component: () => import('@/views/pages/login'),
    name: 'login',
    meta: { title: '登录' },
  },
];

// 模块路由(基于主入口布局页面)
export const moduleRoutes = {
  path: '/',
  component: () => import('@/views/main'),
  name: 'main',
  redirect: { name: 'home' },
  meta: { title: '主入口布局' },
  children: [
    {
      path: '/home',
      component: () => import('@/views/modules/home'),
      name: 'home',
      meta: { title: '首页', isTab: true },
    },
    {
      path: '/addRightsPackage',
      component: () =>
        import('@/views/modules/health/rightsPackage-add-or-update.vue'),
      name: 'rightsPackage',
      meta: { title: '新增权益包', isTab: true },
    },
    {
      path: '/cardListQueryDetail',
      component: () => import('@/views/modules/health/cardListQueryDetail.vue'),
      name: 'cardListQueryDetail',
      meta: { title: '权益卡单次查询详情', isTab: true },
    },
    {
      path: '/healthtickets',
      component: () => import('@/views/modules/serviceOrder/healthtickets.vue'),
      name: 'healthtickets',
      meta: { title: '服务单列表', isTab: true },
    },
    {
      path: '/healthticketsDetails',
      component: () =>
        import('@/views/modules/serviceOrder/healthticketsDetails.vue'),
      name: 'healthticketsDetails',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/advanceDetails',
      component: () =>
        import('@/views/modules/serviceOrder/advanceDetails.vue'),
      name: 'advanceDetails',
      meta: { title: '服务单列详情', isTab: true },
    },

    {
      path: '/assistLeaveHospitalDetail',
      component: () =>
        import('@/views/modules/serviceOrder/assistLeaveHospitalDetail.vue'),
      name: 'assistLeaveHospitalDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/chineseMedicineDetail',
      component: () =>
        import('@/views/modules/serviceOrder/chineseMedicineDetail.vue'),
      name: 'chineseMedicineDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/controlAndConsultationDetail',
      component: () =>
        import('@/views/modules/serviceOrder/controlAndConsultationDetail.vue'),
      name: 'controlAndConsultationDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/greenHospitaltwoDetail',
      component: () =>
        import('@/views/modules/serviceOrder/greenHospitaltwoDetail.vue'),
      name: 'greenHospitaltwoDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/OverseasClassDetail',
      component: () =>
        import('@/views/modules/serviceOrder/OverseasClassDetail.vue'),
      name: 'OverseasClassDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/seriousillnessDetail',
      component: () =>
        import('@/views/modules/serviceOrder/seriousillnessDetail.vue'),
      name: 'seriousillnessDetail',
      meta: { title: '服务单列详情', isTab: true },
    },
    {
      path: '/exclusiveCareDetails',
      component: () =>
        import('@/views/modules/serviceOrder/exclusiveCareDetails.vue'),
      name: 'exclusiveCareDetails',
      meta: { title: '服务单详情', isTab: true },
    },
    {
      path: '/addServiceTickets',
      component: () =>
        import('@/views/modules/serviceOrder/addServiceTickets.vue'),
      name: 'addServiceTickets',
      meta: { title: '新建服务单', isTab: true },
    },
    {
      path: '/iframe',
      component: null,
      name: 'iframe',
      meta: { title: 'iframe', isTab: true },
    },

    {
      path: '/doctorTeam/teamDetail',
      component: () =>
        import('@/views/modules/doctorOms/doctorTeam/detail.vue'),
      name: 'teamDetail',
      meta: { title: '新增医生组', isTab: true },
    },

    {
      path: '/doctorManage/addDoctor',
      component: () =>
        import(
          '@/views/modules/doctorOms/doctorManage/doctorList/addDoctor.vue'
        ),
      name: 'addDoctor',
      meta: { title: '修改医生', isTab: true },
    },
    {
      path: '/doctorManage/doctorDetail',
      component: () =>
        import('@/views/modules/doctorOms/doctorManage/doctorList/detail.vue'),
      name: 'doctorManageDetail',
      meta: { title: '详情', isTab: true },
    },
    //
    {
      path: '/basicDictionary/templateList',
      component: () =>
        import(
          '@/views/modules/doctorOms/basicInfo/organ/followUpTemplate/index.vue'
        ),
      name: 'templateList',
      meta: { title: '随访模板', isTab: true },
    },

    {
      path: '/basicDictionary/consultTypeDetail',
      component: () =>
        import('@/views/modules/doctorOms/basicInfo/consultType/detail.vue'),
      name: 'consultTypeDetail',
      meta: { title: '问诊类型详情' },
      hidden: true,
    },

    {
      path: '/basicDictionary/drugDetail',
      component: () =>
        import('@/views/modules/doctorOms/basicInfo/drugList/detail.vue'),
      name: 'drugDetail',
      meta: { title: '药品详情' },
      hidden: true,
    },

    {
      path: '/privacyPolicy/protocolDetail',
      component: () =>
        import('@/views/modules/doctorOms/privacyPolicy/detail.vue'),
      name: 'protocolDetail',
      meta: { title: '协议详情' },
      hidden: true,
    },

    {
      path: '/rosterManage/revisionDetail',
      component: () =>
        import(
          '@/views/modules/doctorOms/doctorManage/rosterManage/rosterRevision/detail.vue'
        ),
      name: 'revisionDetail',
      meta: { title: '排班详情' },
      hidden: true,
    },

    {
      path: '/doctorManage/consultationDetail',
      component: () =>
        import(
          '@/views/modules/doctorOms/doctorManage/setConsultation/detail.vue'
        ),
      name: 'consultationDetail',
      meta: { title: '问诊配置详情' },
      hidden: true,
    },
    {
      path: '/doctorManage/addBind',
      component: () =>
        import(
          '@/views/modules/doctorOms/doctorManage/pharmacistAccount/add.vue'
        ),
      name: 'addBind',
      meta: { title: '新增绑定' },
      hidden: true,
    },

    {
      path: '/orderManage/textConsultDetail',
      component: () =>
        import(
          '@/views/modules/doctorOms/orderManage/consultationOrderManage/components/graphicConsultation.vue'
        ),
      name: 'furtherConsultation',
      meta: { title: '图文问诊订单详情' },
      hidden: true,
    },

    {
      path: '/orderManage/prescriptionDetail',
      component: () =>
        import(
          '@/views/modules/doctorOms/orderManage/prescriptionAudit/detail.vue'
        ),
      name: 'prescriptionDetail',
      meta: { title: '处方订单详情' },
      hidden: true,
    },

    {
      path: '/orderManage/prescriptionAudit',
      component: () =>
        import(
          '@/views/modules/doctorOms/orderManage/prescriptionAudit/audit.vue'
        ),
      name: 'prescriptionAudit',
      meta: { title: '处方订单审核' },
      hidden: true,
    },

    {
      path: '/healthRecord/secondery',
      component: () => import('@/views/modules/healthRecord/secondery.vue'),
      name: 'secondery',
      meta: { title: '档案查询' },
      hidden: true,
    },
    {
      path: '/healthReminder/detail',
      component: () => import('@/views/modules/healthReminder/detail.vue'),
      name: 'healthReminder',
      meta: { title: '编辑提醒' },
      hidden: true,
    },
  ],
};

export function addDynamicRoute(routeParams, router) {
  // 组装路由名称, 并判断是否已添加, 如是: 则直接跳转
  var routeName = routeParams.routeName;
  var dynamicRoute = window.SITE_CONFIG['dynamicRoutes'].filter(
    (item) => item.name === routeName
  )[0];
  if (dynamicRoute) {
    return router.push({ name: routeName, params: routeParams.params });
  }
  // 否则: 添加并全局变量保存, 再跳转
  dynamicRoute = {
    path: routeName,
    component: () => import(`@/views/modules/${routeParams.path}`),
    name: routeName,
    meta: {
      ...window.SITE_CONFIG['contentTabDefault'],
      menuId: routeParams.menuId,
      title: `${routeParams.title}`,
    },
  };
  router.addRoutes([
    {
      ...moduleRoutes,
      name: `main-dynamic__${dynamicRoute.name}`,
      children: [dynamicRoute],
    },
  ]);
  window.SITE_CONFIG['dynamicRoutes'].push(dynamicRoute);
  router.push({ name: dynamicRoute.name, params: routeParams.params });
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: pageRoutes.concat(moduleRoutes),
});

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 已添加或者当前路由为页面路由, 可直接访问
  if (
    window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] ||
    fnCurrentRouteIsPageRoute(to, pageRoutes)
  ) {
    return next();
  }
  // 获取字典列表, 添加并全局变量保存
  http
    .get('/sys/dict/type/all')
    .then(({ data: res }) => {
      if (res.code !== 0) {
        return;
      }
      window.SITE_CONFIG['dictList'] = res.data;
    })
    .catch(() => {});
  // 获取菜单列表, 添加并全局变量保存
  http
    .get('/sys/menu/nav')
    .then(({ data: res }) => {
      if (res.code !== 0) {
        Vue.prototype.$message.error(res.msg);
        return next({ name: 'login' });
      }
      window.SITE_CONFIG['menuList'] = res.data;
      fnAddDynamicMenuRoutes(window.SITE_CONFIG['menuList']);
      next({ ...to, replace: true });
    })
    .catch(() => {
      next({ name: 'login' });
    });
});

/**
 * 判断当前路由是否为页面路由
 * @param {*} route 当前路由
 * @param {*} pageRoutes 页面路由
 */
function fnCurrentRouteIsPageRoute(route, pageRoutes = []) {
  var temp = [];
  for (var i = 0; i < pageRoutes.length; i++) {
    if (route.path === pageRoutes[i].path) {
      return true;
    }
    if (pageRoutes[i].children && pageRoutes[i].children.length >= 1) {
      temp = temp.concat(pageRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteIsPageRoute(route, temp) : false;
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = [];
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].children && menuList[i].children.length >= 1) {
      temp = temp.concat(menuList[i].children);
      continue;
    }
    // 组装路由
    var route = {
      path: '',
      component: null,
      name: '',
      meta: {
        ...window.SITE_CONFIG['contentTabDefault'],
        menuId: menuList[i].id,
        title: menuList[i].name,
      },
    };
    // eslint-disable-next-line
    let URL = (menuList[i].url || '').replace(/{{([^}}]+)?}}/g, (s1, s2) =>
      eval(s2)
    ); // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
      route['path'] = route['name'] = `i-${menuList[i].id}`;
      route['meta']['iframeURL'] = URL;
    } else {
      URL = URL.replace(/^\//, '').replace(/_/g, '-');
      route['path'] = route['name'] = URL.replace(/\//g, '-');
      route['component'] = () => import(`@/views/modules/${URL}`);
    }
    routes.push(route);
  }
  if (temp.length >= 1) {
    return fnAddDynamicMenuRoutes(temp, routes);
  }
  // 添加路由
  router.addRoutes([
    {
      ...moduleRoutes,
      name: 'main-dynamic-menu',
      children: routes,
    },
    { path: '*', redirect: { name: '404' } },
  ]);
  window.SITE_CONFIG['dynamicMenuRoutes'] = routes;
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = true;
}

export default router;

import http from '@/utils/request';

//   医生信息
const loginDoctorDetail = (data) =>
  http.post('/doctor-reception/loginDoctorDetail', data);

// 订单列表
const queryConsultationOrderIMList = (data) =>
  http.post('/doctor-reception/queryConsultationOrderIMList', data);

// 订单详情
const queryConsultationOrderDetail = (data) =>
  http.post('/doctor-reception/queryConsultationOrderDetail', data);

//  接诊
const acceptConsultation = (data) =>
  http.post('/doctor-reception/accept', data);

//   第一次发言调用

const answerConsultation = (data) =>
  http.post('/doctor-reception/answer', data);

// 完成问诊订单

const completeConsultation = (data) =>
  http.post('/doctor-reception/complete', data);
// 拒绝
const refuseConsultation = (data) =>
  http.post('/doctor-reception/refuse', data);

// /doctor-dic-base/queryList

const queryList = (data) => http.post('/doctor-dic-base/queryList', data);

// 病历

const summaryCreate = (data) =>
  http.post('/doctor-consultation-summary/create', data);

// 查药
const medicalPageQuery = (data) =>
  http.post('/doctor-reception/medical/pageQuery', data);

// 生成处方
const medicalCreate = (data) => http.post('/doctor-prescription/create', data);
export {
  loginDoctorDetail,
  queryConsultationOrderIMList,
  queryConsultationOrderDetail,
  acceptConsultation,
  refuseConsultation,
  answerConsultation,
  completeConsultation,
  queryList,
  summaryCreate,
  medicalPageQuery,
  medicalCreate,
};

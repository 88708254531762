import request from '@/utils/requestDoctor';
import requestUrls from './url';
// import axios from 'axios';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 医生列表
  doctorPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.doctorPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 医生编辑
  edit(query) {
    return request({
      url: baseUrl + requestUrls.edit,
      method: 'post',
      data: query,
    });
  },
  // 冻结医生状态
  freeze(query) {
    return request({
      url: baseUrl + requestUrls.freeze,
      method: 'post',
      data: query,
    });
  },
  // 上线
  online(query) {
    return request({
      url: baseUrl + requestUrls.online,
      method: 'post',
      data: query,
    });
  },
  // 下线
  offline(query) {
    return request({
      url: baseUrl + requestUrls.offline,
      method: 'post',
      data: query,
    });
  },
  audit(query) {
    return request({
      url: baseUrl + requestUrls.audit,
      method: 'post',
      data: query,
    });
  },
  unfreeze(query) {
    return request({
      url: baseUrl + requestUrls.unfreeze,
      method: 'post',
      data: query,
    });
  },
  // 同步医网信
  pushTrust(query) {
    return request({
      url: baseUrl + requestUrls.pushTrust,
      method: 'post',
      data: query,
    });
  },
  // 根据医生id查询医生详情
  queryById(query) {
    return request({
      url: baseUrl + requestUrls.queryById,
      method: 'post',
      data: query,
    });
  },
  // 分页查询白名单
  whitePageQuery(query) {
    return request({
      url: baseUrl + requestUrls.whitePageQuery,
      method: 'post',
      data: query,
    });
  },
  // 提醒
  remind(query) {
    return request({
      url: baseUrl + requestUrls.remind,
      method: 'post',
      data: query,
    });
  },
  // 删除白名单
  whiteDelete(query) {
    return request({
      url: baseUrl + requestUrls.whiteDelete,
      method: 'post',
      data: query,
    });
  },
  // 分页问诊配置查询
  consultationPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.consultationPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 问诊配置编辑
  consultationGet(query) {
    return request({
      url: baseUrl + requestUrls.consultationGet,
      method: 'post',
      data: query,
    });
  },
  // 问诊配置详情
  consultationEdit(query) {
    return request({
      url: baseUrl + requestUrls.consultationEdit,
      method: 'post',
      data: query,
    });
  },
  //  问诊订单分页查询
  orderPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.orderPageQuery,
      method: 'post',
      data: query,
    });
  },
  //  退诊
  consultationOrderCancel(query) {
    return request({
      url: baseUrl + requestUrls.consultationOrderCancel,
      method: 'post',
      data: query,
    });
  },
  // 根据状态统计数据
  countByStatus(query) {
    return request({
      url: baseUrl + requestUrls.countByStatus,
      method: 'post',
      data: query,
    });
  },
  // v1.1
  // 取消图文问诊
  cancelOrder(query) {
    return request({
      url: baseUrl + requestUrls.cancelOrder,
      method: 'post',
      data: query,
    });
  },
  // 查询图文问诊详情
  textQueryDetail(query) {
    return request({
      url: baseUrl + requestUrls.textQueryDetail,
      method: 'post',
      data: query,
    });
  },
  // 查询视频问诊详情
  videoQueryDetail(query) {
    return request({
      url: baseUrl + requestUrls.videoQueryDetail,
      method: 'post',
      data: query,
    });
  },
  // 查询问诊小结
  summaryDetail(query) {
    return request({
      url: baseUrl + requestUrls.summaryDetail,
      method: 'post',
      data: query,
    });
  },
  /* 上传接口 ---------------------------------------------------------------------*/
  // 导入白名单
  // importWhite(query) {
  //   return axios.post(baseUrl + requestUrls.importWhite, query).then((res) => {
  //     return res.data;
  //   });
  // },

  importWhite(query) {
    return request({
      url: baseUrl + requestUrls.importWhite,
      method: 'post',
      data: query,
    });
  },

  // 分页查询绑定记录
  pharmacistPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.pharmacistPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 新增药师绑定
  pharmacistAddBind(query) {
    return request({
      url: baseUrl + requestUrls.pharmacistAddBind,
      method: 'post',
      data: query,
    });
  },
  // 获取管理后台用户信息
  pharmacistGetUserInfo(query) {
    return request({
      url: baseUrl + requestUrls.pharmacistGetUserInfo,
      method: 'post',
      data: query,
    });
  },
  // 查询医生信息
  pharmacistQueryDoctor(query) {
    return request({
      url: baseUrl + requestUrls.pharmacistQueryDoctor,
      method: 'post',
      data: query,
    });
  },
  // 解除绑定
  pharmacistUnBind(query) {
    return request({
      url: baseUrl + requestUrls.pharmacistUnBind,
      method: 'post',
      data: query,
    });
  },
  // 查询图文问诊历史记录
  queryConsultationTalkHistoryList(query) {
    return request({
      url: baseUrl + requestUrls.queryConsultationTalkHistoryList,
      method: 'post',
      data: query,
    });
  },
  // 监管机构列表查询
  regulatoryOrganList(query) {
    return request({
      url: baseUrl + requestUrls.regulatoryOrganList,
      method: 'post',
      data: query,
    });
  },
  // 同步监管部门
  pushSupervisePlat(query) {
    return request({
      url: baseUrl + requestUrls.pushSupervisePlat,
      method: 'post',
      data: query,
    });
  },
  // 查询医生所在小组
  findListByDoctorId(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.findListByDoctorId,
      data: query,
    });
  },
  // 添加医生
  addDoctor(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.addDoctor,
      data: query,
    });
  },
  // 移除医生
  removeDoctor(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.removeDoctor,
      data: query,
    });
  },
  // 查询专病医生分页
  queryFurtherConsultationDoctorList(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.queryFurtherConsultationDoctorList,
      data: query,
    });
  },
  // 查询白名单配置开关状态
  queryWhiteConfigStatus(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.queryWhiteConfigStatus,
      data: query,
    });
  },
  // 操作白名单
  operateWhiteStatus(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.operateWhiteStatus,
      data: query,
    });
  },
  // 物料分页查询
  materialPageQuery(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.materialPageQuery,
      data: query,
    });
  },
  // 物料详情
  materialDetail(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.materialDetail,
      data: query,
    });
  },
  // 物料新增
  materialAdd(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.materialAdd,
      data: query,
    });
  },
  // 物料修改
  materialEdit(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.materialEdit,
      data: query,
    });
  },
  // 获取物料url
  getMaterialQrCodeUrl(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.getMaterialQrCodeUrl,
      data: query,
    });
  },
  // 分页查询复诊医生列表
  furtherConsultationDoctorList(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.furtherConsultationDoctorList,
      data: query,
    });
  },
  // 物料统计查询
  materialCountQuery(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.materialCountQuery,
      data: query,
    });
  },
  // 查询物料的报到记录
  queryMaterialCheckInLog(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.queryMaterialCheckInLog,
      data: query,
    });
  },
  // 导出报到记录
  exportCheckInLog(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.exportCheckInLog,
      data: query,
      responseType: 'blob',
    });
  },
  /* ------------------------------- IM咨询小组 ------------------------------- */
  // 查询医患小组列表
  queryPageByCondition(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.queryPageByCondition,
      data: query,
    });
  },
  // 查询医患小组详情
  queryGroupDetail(query) {
    return request({
      method: 'get',
      url: baseUrl + requestUrls.queryGroupDetail,
      params: query,
    });
  },
  // 设置医患小组订阅开关
  setImGroupConfigure(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.setImGroupConfigure,
      data: query,
    });
  },
  // 移除医生
  removeDoctorMember(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.removeDoctorMember,
      data: query,
    });
  },
  // 添加医生
  addDoctorMember(query) {
    return request({
      method: 'post',
      url: baseUrl + requestUrls.addDoctorMember,
      data: query,
    });
  },
};

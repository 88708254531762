import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import getters from './getters';
const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

import { Message } from 'element-ui';
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    // 导航条, 布局风格, default(白色) / colorful(鲜艳)
    navbarLayoutType: 'colorful',
    // 侧边栏, 布局皮肤, default(白色) / dark(黑色)
    sidebarLayoutSkin: 'dark',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    sidebarMenuList: [],
    sidebarMenuActiveName: '',
    // 内容, 是否需要刷新
    contentIsNeedRefresh: false,
    // 内容, 标签页(默认添加首页)
    contentTabs: [
      {
        ...window.SITE_CONFIG['contentTabDefault'],
        name: 'home',
        title: 'home',
      },
    ],
    contentTabsActiveName: 'home',
    getCall: false,
    roomId: '',
    groupId: '',
    fromOrigin: '', // ZHONGAN MIUS
    miusGetCall: false,
    miusRoomId: '',
    miusGroupId: '',
    current: Date.now(), // 当前时间
    intervalID: 0,
    message: undefined,
    docLogOut: '',
  },
  modules,
  mutations: {
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now();
      }, 500);
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID);
      state.intervalID = 0;
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close();
      }
      state.message = Message({
        message: options.message,
        type: options.type || 'success',
        duration: options.duration || 2000,
        offset: 40,
      });
    },
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key]);
      });
    },
    entityId(state, arg) {
      state.entityId = arg;
    },
    callIngChange(state, val) {
      state.getCall = true;
      state.roomId = val.roomId;
      state.groupId = val.uuid;
      state.fromOrigin = val.fromOrigin;
    },
    closeCall(state, val) {
      if (state.groupId && state.groupId === val.uuid) {
        state.getCall = false;
      }
    },
    closeCallMask(state) {
      state.getCall = false;
    },
    logOutNotice(state, val) {
      console.log(val, 'logOutNotice');
      state.docLogOut = val;
    },

    // miusCallIngChange(state, val) {
    //     state.miusGetCall = true;
    //     state.miusRoomId = val.roomId;
    //     state.miusGroupId = val.uuid;
    // },
    // miusCloseCall(state, val) {
    //     if (state.miusGroupId && state.miusGroupId === val.uuid) {
    //         state.miusGetCall = false;
    //     }
    // },
    // miusCloseCallMask(state) {
    //     state.miusGetCall = false;
    // },
    addService(state, val) {
      state.userRightObj = val;
    },
  },

  getters,
});

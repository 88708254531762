import TIM from 'tim-js-sdk';
// import COSSDK from 'cos-js-sdk-v5';
import TIMUploadPlugin from 'tim-upload-plugin';

import store from '../store/index';
import { emojiMap, emojiUrl } from './emojiMap';
// 初始化 SDK 实例

const initIm = (SDKAppID) => {
  if (window.$tim) return;
  // 初始化 SDK 实例
  const tim = TIM.create({
    SDKAppID: SDKAppID || 1400518416,
  });
  // tim.registerPlugin({ 'cos-js-sdk': COSSDK });
  tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
  window.setLogLevel = tim.setLogLevel;
  window.$tim = tim;

  // 无日志级别:4 全日志0
  tim.setLogLevel(1);
  // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
  tim.on(TIM.EVENT.SDK_READY, onReadyStateUpdate, this);
  // SDK NOT READT
  tim.on(TIM.EVENT.SDK_NOT_READY, onReadyStateUpdate, this);
  // 被踢出
  tim.on(TIM.EVENT.KICKED_OUT, onKickOut);

  //内部错误
  tim.on(TIM.EVENT.ERROR, onError);
  //接收消息
  tim.on(TIM.EVENT.MESSAGE_RECEIVED, onReceiveMessage);
  // 会话列表更新
  tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onUpdateConversationList);
  // 群组列表更新
  tim.on(TIM.EVENT.GROUP_LIST_UPDATED, onUpdateGroupList);
  // 网络监测
  tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
  // 已读回执
  tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);

  function onMessageReadByPeer() {}
  function onUpdateConversationList(event) {
    console.log('onUpdateConversationList:会话列表更新了!utils', event);
    store.commit('updateConversationList', event.data);
  }

  function onUpdateGroupList(event) {
    console.log('群组更新了!', event);
    store.commit('updateGroupList', event.data);
  }

  function onNetStateChange(event) {
    store.commit('showMessage', checkoutNetState(event.data.state));
  }
  function checkoutNetState(state) {
    switch (state) {
      case TIM.TYPES.NET_STATE_CONNECTED:
        return { message: '已接入网络', type: 'success' };
      case TIM.TYPES.NET_STATE_CONNECTING:
        return { message: '当前网络不稳定', type: 'warning' };
      case TIM.TYPES.NET_STATE_DISCONNECTED:
        return { message: '当前网络不可用', type: 'error' };
      default:
        return '';
    }
  }

  function onReceiveMessage({ data: messageList }) {
    store.commit('pushCurrentMessageList', messageList);
  }

  function onReadyStateUpdate({ name }) {
    const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false;
    store.commit('toggleIsSDKReady', isSDKReady);

    if (isSDKReady) {
      tim
        .getMyProfile()
        .then(({ data }) => {
          store.commit('updateCurrentUserProfile', data);
        })
        .catch((error) => {
          store.commit('showMessage', {
            type: 'error',
            message: error.message,
          });
        });
    }
  }

  function onKickOut(event) {
    store.commit('showMessage', {
      message: `${kickedOutReason(event.data.type)}被踢出，请重新登录。`,
      type: 'error',
    });
    store.commit('toggleIsLogin', false);
    store.commit('reset');
  }

  function onError({ data }) {
    if (data.message !== 'Network Error') {
      store.commit('showMessage', {
        message: data.message,
        type: 'error',
      });
    }
  }
};
/**
 * 利用 document.title 做新消息提示
 * @export
 * @param {Number} count
 */
const titleNotify = (count) => {
  const hasNewMessage = count > 0;
  const txt = '未读消息：';
  if (hasNewMessage) {
    if (document.title.search(/\((.*?)\)/) >= 0) {
      document.title = document.title.replace(
        /\((.*?)\)/,
        `(${count > 99 ? txt + '99+' : txt + count})`
      );
    } else {
      document.title = `(${count})${document.title}`;
    }
  } else {
    document.title = document.title.replace(/\((.*?)\)/, '');
  }
};

const filterCallingMessage = (currentMessageList) => {
  currentMessageList.forEach((item) => {
    if (item.callType) {
      // 对于自己伪造的消息不需要解析
      return;
    }
    if (item.type === TIM.TYPES.MSG_CUSTOM) {
      let payloadData = {};
      try {
        payloadData = JSON.parse(item.payload.data);
      } catch (e) {
        payloadData = {};
      }
      if (payloadData.businessID === 1) {
        if (item.conversationType === TIM.TYPES.CONV_GROUP) {
          if (payloadData.actionType === 5) {
            item.nick = payloadData.inviteeList
              ? payloadData.inviteeList.join(',')
              : item.from;
          }
          let _text = window.trtcCalling.extractCallingInfoFromMessage(item);
          let group_text = `${_text}`;
          item.type = TIM.TYPES.MSG_GRP_TIP;
          let customData = {
            operationType: 256,
            text: group_text,
            userIDList: [],
          };
          item.payload = customData; //JSON.stringify(customData)
        }
        if (item.conversationType === TIM.TYPES.CONV_C2C) {
          let c2c_text = window.trtcCalling.extractCallingInfoFromMessage(item);
          let customData = {
            text: c2c_text,
          };
          item.payload = customData; //JSON.stringify(customData)
        }
      }
    }
  });
};

const decodeText = (payload) => {
  let renderDom = [];
  // 文本消息
  let temp = payload.text;
  let left = -1;
  let right = -1;
  while (temp !== '') {
    left = temp.indexOf('[');
    right = temp.indexOf(']');
    switch (left) {
      case 0:
        if (right === -1) {
          renderDom.push({
            name: 'text',
            text: temp,
          });
          temp = '';
        } else {
          let _emoji = temp.slice(0, right + 1);
          if (emojiMap[_emoji]) {
            renderDom.push({
              name: 'img',
              src: emojiUrl + emojiMap[_emoji],
            });
            temp = temp.substring(right + 1);
          } else {
            renderDom.push({
              name: 'text',
              text: '[',
            });
            temp = temp.slice(1);
          }
        }
        break;
      case -1:
        renderDom.push({
          name: 'text',
          text: temp,
        });
        temp = '';
        break;
      default:
        renderDom.push({
          name: 'text',
          text: temp.slice(0, left),
        });
        temp = temp.substring(left);
        break;
    }
  }
  return renderDom;
};

export { filterCallingMessage, titleNotify, initIm, decodeText };

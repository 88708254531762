import api from '@/api/prescriptionAuditConfig';
// console.log(querySpecList, edit);
const state = {
  specList: [],
  specTypeList: [],
};

const mutations = {
  getSpecList: (state, data) => {
    state.specList = data;
  },
  getSpecTypeList: (state, data) => {
    state.specTypeList = data;
  },
};

const actions = {
  querySpecList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .querySpecList(product)
        .then((response) => {
          commit('getSpecList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  querySpecTypeList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .querySpecTypeList(product)
        .then((response) => {
          commit('getSpecTypeList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

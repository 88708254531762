import Vue from 'vue';

/**
 * 限制不能输入表情
 */
Vue.directive('checkPermission', function(el) {
  var oldVal = el.querySelector('input').value;
  if (oldVal !== '') {
    var newVal = oldVal.replace( /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g, '');
    if (oldVal !== newVal) { // 不加判断的话，使用自定义指令的输入框，无法通过(clearable)清空
      el.querySelector('input').value = newVal;
      el.querySelector('input').dispatchEvent(new Event('input'));
    }
  }
});
// 限制输入只能为数字
Vue.directive('onlyNumber', function(el) {
  var oldVal = el.querySelector('input').value;
  if (oldVal !== '') {
    var newVal = oldVal.replace( /[^\d]/g, '');
    if (oldVal !== newVal) { // 不加判断的话，使用自定义指令的输入框，无法通过(clearable)清空
      el.querySelector('input').value = newVal;
      el.querySelector('input').dispatchEvent(new Event('input'));
    }
  }
});

import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 分页查询随访项目
  interviewProjectPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.interviewProjectPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 更新随访项目
  interviewProjectUpdate(query) {
    return request({
      url: baseUrl + requestUrls.interviewProjectUpdate,
      method: 'post',
      data: query,
    });
  },
  // 删除随访项目
  interviewProjectDel(query) {
    return request({
      url: baseUrl + requestUrls.interviewProjectDel,
      method: 'post',
      data: query,
    });
  },
  // 新增随访项目
  interviewProjectCreate(query) {
    return request({
      url: baseUrl + requestUrls.interviewProjectCreate,
      method: 'post',
      data: query,
    });
  },
  // 查询所有项目
  interviewProjectList(query) {
    return request({
      url: baseUrl + requestUrls.interviewProjectList,
      method: 'post',
      data: query,
    });
  },
};

const video = {
  state: {
    videoRoom: 0,
    isBusy: false,
    // trtcCalling: null,
  },
  mutations: {
    GENERATE_VIDEO_ROOM(state, videoRoom) {
      if (videoRoom) {
        state.videoRoom = videoRoom;
      } else {
        state.videoRoom = Math.ceil(Math.random() * (2 ** 32 - 1));
      }
    },
    UPDATE_ISBUSY(state, isBusy) {
      state.isBusy = isBusy;
    },
    // UPDATE_TRTC_CALLING(state, trtcCalling) {
    //   state.trtcCalling = trtcCalling;
    // },
  },
};

export default video;

import api from '@/api/orderManage';
const state = {};

const mutations = {};

const actions = {
  pageQueryResource({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .pageQueryResource(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryDetail({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .queryDetail(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // queryList({ commit }, product) {
  //
  //   return new Promise((resolve, reject) => {
  //     api.queryList(product).then(response => {
  //       resolve(response);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

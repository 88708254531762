import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 新增随访模板
  interviewCreate(query) {
    return request({
      url: baseUrl + requestUrls.interviewCreate,
      method: 'post',
      data: query,
    });
  },
  // 删除随访模板
  interviewDelete(query) {
    return request({
      url: baseUrl + requestUrls.interviewDelete,
      method: 'post',
      data: query,
    });
  },
  // 随访详情
  interviewDetail(query) {
    return request({
      url: baseUrl + requestUrls.interviewDetail,
      method: 'post',
      data: query,
    });
  },
  // 分页查询随模板
  interviewPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.interviewPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 更新随访模板
  interviewUpdate(query) {
    return request({
      url: baseUrl + requestUrls.interviewUpdate,
      method: 'post',
      data: query,
    });
  },
};

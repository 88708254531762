import {
  getImgSign
} from '@/api/img/index';

const state = {
  policy: {

  }
};

const mutations = {
  getPolicy(state, data) {
    state.policy = data;
  }
};

const actions = {
  getImgSign({ commit }, data) {
    return new Promise((resolve, reject) => {
      getImgSign(data).then(({data}) => {
        commit('getPolicy', data);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};


import Vue from 'vue';
import Element from 'element-ui';
import App from '@/App';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import http from '@/utils/request';
import SvgIcon from '@/utils/form-generator/components/SvgIcon';
import { hasPermission, getDictLabel } from '@/utils';
import VueBus from 'vue-bus';
import cloneDeep from 'lodash/cloneDeep';
import VueCompositionAPI from '@vue/composition-api';
import renRadioGroup from '@/components/ren-radio-group';
import renSelect from '@/components/ren-select';
import renProcessMultiple from '@/components/ren-process-multiple';
import renProcessStart from '@/components/ren-process-start';
import renProcessRunning from '@/components/ren-process-running';
import renProcessDetail from '@/components/ren-process-detail';
import renDeptTree from '@/components/ren-dept-tree';
import renRegionTree from '@/components/ren-region-tree';
// import Tinymce from '@/components/tinymce/index.vue';
import Chat from 'jwchat';

import * as filters from './filters'; // global filters
import '@/directive/inputValidtor';

import 'xe-utils';
import 'vxe-table/lib/index.css';
import VXETable from 'vxe-table';

import '@/icons';
import '@/element-ui/theme/index.css';
import '@/assets/scss/aui.scss';
import '@/utils/form-generator/styles/index.scss';

import LemonIMUI from 'lemon-imui';
import api from '@/api/api';
// import LemonIMUI from './views/modules/video/packages';

import 'lemon-imui/dist/index.css';

import TIM from 'tim-js-sdk';
import { playAudio } from '@/utils/audio';
//图片放大组件
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer';
import 'vue-directive-image-previewer/dist/assets/style.css';

import trtcCalling from './utils/initRtc';
import TRTCCalling from 'trtc-calling-js';
window.TRTCCalling = TRTCCalling;
window.trtcCalling = trtcCalling;
Vue.prototype.trtcCalling = trtcCalling;
Vue.prototype.TRTCCalling = TRTCCalling;

Vue.use(VueDirectiveImagePreviewer);
Vue.use(VueCompositionAPI);
Vue.prototype.TIM = TIM;
Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false;

// Vue.component('tinymce', Tinymce);
Vue.component('svg-icon', SvgIcon);

Vue.use(LemonIMUI);
Vue.use(renRadioGroup);
Vue.use(renSelect);
Vue.use(renDeptTree);
Vue.use(renRegionTree);
Vue.use(renProcessMultiple);
Vue.use(renProcessStart);
Vue.use(renProcessRunning);
Vue.use(renProcessDetail);
Vue.use(VueBus);
Vue.use(VXETable);
Vue.use(Chat);
Vue.use(Element, {
  size: 'default',
  i18n: (key, value) => i18n.t(key, value),
});
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.prototype.$store = store;
// 挂载全局
Vue.prototype.$http = http;
Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$getDictLabel = getDictLabel;
Vue.prototype.$checkPermission = () => true;
Vue.prototype.$api = api;
document.body.addEventListener('click', function (e) {
  let event = e || window.event;
  let target = event.target || event.srcElement;
  let clickMusic = target.getAttribute('clickMusic');
  if (clickMusic === 'true') playAudio();
  else return false;
});

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state);
// 创建一个全局 mixin

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 病历预审
  auditInfo(query) {
    return request({
      url: baseUrl + requestUrls.auditInfo,
      method: 'post',
      data: query,
    });
  },
  // 单病历查询
  getInfo(query) {
    return request({
      url: baseUrl + requestUrls.getInfo,
      method: 'post',
      data: query,
    });
  },
  // 分页查询
  medicalPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.medicalPageQuery,
      method: 'post',
      data: query,
    });
  },
  queryMedicalRecordIds(query) {
    return request({
      url: baseUrl + requestUrls.queryMedicalRecordIds,
      method: 'post',
      data: query,
    });
  },
};

import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 分页查询号源列表
  sourcePageQuery(query) {
    return request({
      url: baseUrl + requestUrls.sourcePageQuery,
      method: 'post',
      data: query,
    });
  },
  // 编辑号源模板
  sourceEdit(query) {
    return request({
      url: baseUrl + requestUrls.sourceEdit,
      method: 'post',
      data: query,
    });
  },
  // 分页查询排班模板列表
  schedulePageQuery(query) {
    return request({
      url: baseUrl + requestUrls.schedulePageQuery,
      method: 'post',
      data: query,
    });
  },
  // 编辑排班模板
  scheduleEdit(query) {
    return request({
      url: baseUrl + requestUrls.scheduleEdit,
      method: 'post',
      data: query,
    });
  },
  // 批量编辑排班模板
  scheduleBatchEdit(query) {
    return request({
      url: baseUrl + requestUrls.scheduleBatchEdit,
      method: 'post',
      data: query,
    });
  },
  // 查询医生列表
  queryDoctorList(query) {
    return request({
      url: baseUrl + requestUrls.queryDoctorList,
      method: 'post',
      data: query,
    });
  },
  // 查询医生排班数据详情
  doctorScheduleDetail(query) {
    return request({
      url: baseUrl + requestUrls.doctorScheduleDetail,
      method: 'post',
      data: query,
    });
  },
  // 排班
  openSchedule(query) {
    return request({
      url: baseUrl + requestUrls.openSchedule,
      method: 'post',
      data: query,
    });
  },
  // 停诊
  closeSchedule(query) {
    return request({
      url: baseUrl + requestUrls.closeSchedule,
      method: 'post',
      data: query,
    });
  },
  // 开诊
  startSchedule(query) {
    return request({
      url: baseUrl + requestUrls.startSchedule,
      method: 'post',
      data: query,
    });
  },
};

import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 监管机构信息详情查询
  queryRegulatoryOrganDetail(query) {
    return request({
      url: baseUrl + requestUrls.queryRegulatoryOrganDetail,
      method: 'post',
      data: query,
    });
  },
  // 监管机构信息编辑
  regulatoryOrganEdit(query) {
    return request({
      url: baseUrl + requestUrls.regulatoryOrganEdit,
      method: 'post',
      data: query,
    });
  },
};

import axios from 'axios';
import router from '@/router';
import qs from 'qs';
import { clearLoginInfo } from '@/utils';
import isPlainObject from 'lodash/isPlainObject';

const http = axios.create({
  // baseURL: window.SITE_CONFIG.apiURL,
  timeout: 1000 * 180,
  withCredentials: true,
});

// https://tyraelapidev.miao.cn/web/api
// 开发：tyraelapidev.miao.cn/web/api
// 测试：tyraelapitest.miao.cn/web/api
// 生产：tyraelapi.miao.cn/web/api
/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] =
      sessionStorage.getItem('language') || 'zh-CN';
    config.headers.token = sessionStorage.getItem('token') || '';
    // 默认参数
    var defaults = {};
    const webRex = /\/web/;
    if (config.url && webRex.test(config.url.toLowerCase())) {
      config.baseURL = window.SITE_CONFIG.webApiURL;
    } else {
      config.baseURL = window.SITE_CONFIG.apiURL;
    }
    // 防止缓存，GET请求默认带_t参数
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        ...{ _t: new Date().getTime() },
      };
    }
    if (isPlainObject(config.params)) {
      config.params = {
        ...defaults,
        ...config.params,
      };
    }
    if (isPlainObject(config.data)) {
      config.data = {
        ...defaults,
        ...config.data,
      };
      if (
        /^application\/x-www-form-urlencoded/.test(
          config.headers['content-type']
        )
      ) {
        config.data = qs.stringify(config.data);
      }
    }

    // get序列化
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (response.data.code === 401 || response.data.code === 10001) {
      clearLoginInfo();
      // this.$route.fullPath !== '/login' &&
      router.replace({ name: 'login' });
      return Promise.reject(response.data.msg);
    }
    return response;
  },
  (error) => {
    // console.error(error);
    return Promise.reject(error);
  }
);

export default http;

import request from '@/utils/requestDoctor';
import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 分页查询随访报告列表
  reportPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.reportPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 查询随访报告详情
  queryReportDetail(query) {
    return request({
      url: baseUrl + requestUrls.queryReportDetail,
      method: 'post',
      data: query,
    });
  },
  // 审核报告
  auditReport(query) {
    return request({
      url: baseUrl + requestUrls.auditReport,
      method: 'post',
      data: query,
    });
  },
  // 查询报告id列表
  queryReportIds(query) {
    return request({
      url: baseUrl + requestUrls.queryReportIds,
      method: 'post',
      data: query,
    });
  },
};

import api from '@/api/medicalHistory';
const state = {
  medicalHistoryList: [],
};

const mutations = {
  getMedicalHistoryList: (state, data) => {
    state.medicalHistoryList = data;
  },
};

const actions = {
  medicalPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .medicalPageQuery(product)
        .then((response) => {
          resolve(response);
          commit('getMedicalHistoryList', response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import request from '@/utils/requestDoctor';
import advanceListUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
export default {
  // 协议编辑
  agreementEdit(query) {
    return request({
      url: baseUrl + advanceListUrls.agreementEdit,
      method: 'post',
      data: query,
    });
  },
  // 分页查询
  agreementPageQuery(query) {
    return request({
      url: baseUrl + advanceListUrls.agreementPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 协议状态变更
  agreementChangeStatus(query) {
    return request({
      url: baseUrl + advanceListUrls.agreementChangeStatus,
      method: 'post',
      data: query,
    });
  },
  // 协议详情
  agreementDetail(query) {
    return request({
      url: baseUrl + advanceListUrls.agreementDetail,
      method: 'post',
      data: query,
    });
  },
};

import moment from 'moment';
import store from '@/store';

/**
 * 权限
 * @param {*} key
 */
export function hasPermission(key) {
  return window.SITE_CONFIG['permissions'].indexOf(key) !== -1 || false;
}

/**
 * 获取字典数据列表
 * @param dictType  字典类型
 */
export function getDictDataList(dictType) {
  const type = window.SITE_CONFIG['dictList'].find(
    (element) => element.dictType === dictType
  );
  if (type) {
    return type.dataList;
  } else {
    return [];
  }
}

/**
 * 获取字典名称
 * @param dictType  字典类型
 * @param dictValue  字典值
 */
export function getDictLabel(dictType, dictValue) {
  const type = window.SITE_CONFIG['dictList'].find(
    (element) => element.dictType === dictType
  );
  if (type) {
    const val = type.dataList.find(
      (element) => element.dictValue === dictValue + ''
    );
    if (val) {
      return val.dictLabel;
    } else {
      return dictValue;
    }
  } else {
    return dictValue;
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  store.commit('resetStore');
  sessionStorage.removeItem('token');
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = false;
}

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(
      16
    );
  });
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList() {
  var res = [];
  var list = document.querySelectorAll('svg symbol');
  for (var i = 0; i < list.length; i++) {
    res.push(list[i].id);
  }

  return res;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k]);
      continue;
    }
    if (!temp[data[k][pid]]['children']) {
      temp[data[k][pid]]['children'] = [];
    }
    temp[data[k][pid]]['children'].push(data[k]);
    data[k]['_level'] = (temp[data[k][pid]]._level || 0) + 1;
  }
  return res;
}

// 日期差
export function DateDiff(sDate1, sDate2) {
  //yyyy-mm-dd
  if (!sDate1 || !sDate2) {
    return;
  }
  let aDate, oDate1, oDate2, iDays;
  aDate = sDate1.split('-');
  oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]); //转换为12-18-2006格式
  aDate = sDate2.split('-');
  oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数
  return iDays + 1;
}

export const formatDate = (time = +new Date()) => {
  let date = new Date(time + 8 * 3600 * 1000);
  return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '/');
};
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    );
  }
}

/**
 * @description: 导出excel
 * @param {*} res 文件流
 * @param {*} fileName 文件名
 * @return {*}
 */
export function downloadUrl(res, fileName) {
  const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
  if (navigator.userAgent.indexOf('Edge') > -1) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href);
    document.body.removeChild(elink);
  }
}

/**
 * @description: 计算时长
 * @param {*} startTime: 开始时间
 * @param {*} endTime 结束时间
 * @return {*}
 */
export function getDuration(startTime, endTime) {
  if (!endTime) return '--';

  const start = moment(startTime);
  const end = moment(endTime);
  const diff = moment.duration(end - start, 'ms');
  const days = diff.get('days') > 0 ? `${diff.get('days')}天` : '';
  const hours = diff.get('hours') > 0 ? `${diff.get('hours')}时` : '';
  const minutes = diff.get('minutes') > 0 ? `${diff.get('minutes')}分` : '';
  const seconds = diff.get('seconds') > 0 ? `${diff.get('seconds')}秒` : '';
  return `${days}${hours}${minutes}${seconds}`;
}

import { loginoutTest, getUserInfo, generateCipher } from '@/api/public';
import Vue from 'vue';
const state = {
  newToken: '',
  newRoles: [],
  UserInfo: {},
  name: '',
  rsaPublicKey: '', // RSA公钥
  aesPrivateKey: '', // AES密钥
};

const mutations = {
  NEW_TOKEN: (state, token) => {
    state.newToken = token;
  },
  NEW_ROLES: (state, newRoles) => {
    state.newRoles = newRoles;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  User_Info: (state, UserInfo) => {
    state.UserInfo = UserInfo;
  },
  SET_AES_PUBLIC_KEY: (state, publicKey) => {
    state.rsaPublicKey = publicKey;
  },
  SET_AES_PRIVATE_KEY: (state, privateKey) => {
    state.aesPrivateKey = privateKey;
  },
};

const actions = {
  loginoutTest({ commit }, product) {
    return new Promise((resolve, reject) => {
      loginoutTest(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserInfo({ commit }, product) {
    return new Promise((resolve, reject) => {
      if (!window.__POWERED_BY_QIANKUN__) {
        getUserInfo(product)
          .then((response) => {
            if (response.code === 0) {
              commit('SET_NAME', response.data.username);
              commit('NEW_ROLES', response.data.appnodes);
              commit('User_Info', {
                username: response.data.username,
                uid: response.data.uid,
                tel: response.data.tel,
                nickname: response.data.nickname,
                email: response.data.email,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        const response = Vue.prototype.userInfo;
        if (response) {
          commit('SET_NAME', response.data.username);
          commit('NEW_ROLES', response.data.appnodes);
          commit('User_Info', {
            username: response.data.username,
            uid: response.data.uid,
            tel: response.data.tel,
            nickname: response.data.nickname,
            email: response.data.email,
          });
          resolve(response);
        } else {
          reject();
        }
      }
    });
  },
  generateCipher({ commit }, cipherInfo) {
    return new Promise((resolve, reject) => {
      generateCipher(cipherInfo)
        .then((response) => {
          commit('SET_AES_PUBLIC_KEY', response.data.public_key);

          // 随机生成16位字符串 - AES密钥
          var num = '';
          for (var i = 0; i <= 15; i++) {
            var tmp = Math.ceil(Math.random() * 15);
            if (tmp > 9) {
              switch (tmp) {
                case 10:
                  num += 'a';
                  break;
                case 11:
                  num += 'b';
                  break;
                case 12:
                  num += 'c';
                  break;
                case 13:
                  num += 'd';
                  break;
                case 14:
                  num += 'e';
                  break;
                case 15:
                  num += 'f';
                  break;
              }
            } else {
              num += tmp;
            }
          }
          commit('SET_AES_PRIVATE_KEY', num);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

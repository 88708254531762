import request from '@/utils/requestDoctor';
import requestUrls from './url';
import axios from 'axios';
const baseUrl = 'https://scusapi.shanci.tech';
export default {
  // 查询处方审核规范列表
  querySpecList(query) {
    return request({
      url: baseUrl + requestUrls.querySpecList,
      method: 'post',
      data: query,
    });
  },
  editSpec(query) {
    return request({
      url: baseUrl + requestUrls.editSpec,
      method: 'post',
      data: query,
    });
  },

  // 删除处方审核规范字典
  deleteSpec(query) {
    return request({
      url: baseUrl + requestUrls.deleteSpec,
      method: 'post',
      data: query,
    });
  },

  // 查询处方类型
  querySpecTypeList(query) {
    return request({
      url: baseUrl + requestUrls.querySpecTypeList,
      method: 'post',
      data: query,
    });
  },
  // 编辑处方类型
  editSpecType(query) {
    return request({
      url: baseUrl + requestUrls.editSpecType,
      method: 'post',
      data: query,
    });
  },
  // 删除处方类型
  deleteSpecType(query) {
    return request({
      url: baseUrl + requestUrls.deleteSpecType,
      method: 'post',
      data: query,
    });
  },
  // 查询处方审核规范明细列表
  querySpecDetailList(query) {
    return request({
      url: baseUrl + requestUrls.querySpecDetailList,
      method: 'post',
      data: query,
    });
  },
  // 编辑处方审核规范明细
  editSpecDetail(query) {
    return request({
      url: baseUrl + requestUrls.editSpecDetail,
      method: 'post',
      data: query,
    });
  },
  // 删除审核明细
  deleteSpecDetail(query) {
    return request({
      url: baseUrl + requestUrls.deleteSpecDetail,
      method: 'post',
      data: query,
    });
  },
  // 根据审核规范编码查询规范明细
  querySpecDetailListByCode(query) {
    return request({
      url: baseUrl + requestUrls.querySpecDetailListByCode,
      method: 'post',
      data: query,
    });
  },
  // 处方审核规范上移下移
  specMoveUpOrDown(query) {
    return request({
      url: baseUrl + requestUrls.specMoveUpOrDown,
      method: 'post',
      data: query,
    });
  },
  // 处方审核规范类型上移下移
  specTypeMoveUpOrDown(query) {
    return request({
      url: baseUrl + requestUrls.specTypeMoveUpOrDown,
      method: 'post',
      data: query,
    });
  },
  // 处方审核规范明细上移下移
  specDetailMoveUpOrDown(query) {
    return request({
      url: baseUrl + requestUrls.specDetailMoveUpOrDown,
      method: 'post',
      data: query,
    });
  },
  /* 上传接口 ---------------------------------------------------------------------*/
  // 导入清单
  importClaimOrdersExcel(query) {
    return axios.post(requestUrls.importClaimOrdersExcel, query).then((res) => {
      return res.data;
    });
  },
};

const requestUrls = {
  /* ---------------------------------- public sso ---------------------------------- */
  sourcePageQuery: '/doctor-source-model/pageQuery', // 分页查询号源模板列表
  sourceEdit: '/doctor-source-model/edit', // 编辑号源模板
  schedulePageQuery: '/doctor-schedule-model/pageQuery', // 分页查询排班模板列表
  scheduleEdit: '/doctor-schedule-model/edit', // 编辑排班模板
  scheduleBatchEdit: '/doctor-schedule-model/batchEdit', // 批量编辑排班模板
  queryDoctorList: '/doctor-info/pageQuery', // 查询医生列表
  doctorScheduleDetail: '/doctor-schedule-data/queryDoctorScheduleList', // 查询医生排班数据详情
  openSchedule: '/doctor-schedule-data/openSchedule', // 排班
  closeSchedule: '/doctor-schedule-data/closeSchedule', // 停诊
  startSchedule: '/doctor-schedule-data/startSchedule', // 开诊
  /* ----------------------------------- RSA 公钥 ---------------------------------- */
  cipher: process.env.VUE_APP_CIPHER,
};
export default requestUrls;

import request from '@/utils/requestDoctor';
import request1 from '@/utils/request';

import requestUrls from './url';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 健康咨询列表
  pageQueryResource(query) {
    return request({
      url: baseUrl + requestUrls.pageQueryResource,
      method: 'post',
      data: query,
    });
  },
  // 查询健康咨询订单详情
  queryDetail(query) {
    return request({
      url: baseUrl + requestUrls.queryDetail,
      method: 'post',
      data: query,
    });
  },
  // 取消健康咨询资源
  cancelResource(query) {
    return request({
      url: baseUrl + requestUrls.cancelResource,
      method: 'post',
      data: query,
    });
  },
  // 分页查询处方列表
  prescriptionPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.prescriptionPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 根据id查询处方详情
  prescriptionDetailById(query) {
    return request({
      url: baseUrl + requestUrls.prescriptionDetailById,
      method: 'post',
      data: query,
    });
  },
  // 查询处方详情
  prescriptionDetail(query) {
    return request({
      url: baseUrl + requestUrls.prescriptionDetail,
      method: 'post',
      data: query,
    });
  },
  // 审方签名确认
  auditSignConfirm(query) {
    return request({
      url: baseUrl + requestUrls.auditSignConfirm,
      method: 'post',
      data: query,
    });
  },
  // 审核处方
  prescriptionAudit(query) {
    return request({
      url: baseUrl + requestUrls.prescriptionAudit,
      method: 'post',
      data: query,
    });
  },
  // 签章
  trustSignature(query) {
    return request({
      url: baseUrl + requestUrls.trustSignature,
      method: 'post',
      data: query,
    });
  },
  // 处方流转列表
  distributionPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.distributionPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 处方流转详情
  distributionDetail(query) {
    return request({
      url: baseUrl + requestUrls.distributionDetail,
      method: 'post',
      data: query,
    });
  },
  // 查询复诊订单详情
  furtherConsultationDetail(query) {
    return request({
      url: baseUrl + requestUrls.furtherConsultationDetail,
      method: 'post',
      data: query,
    });
  },
  // 查询群聊历史记录
  queryGroupChatMessageList(query) {
    return request({
      url: baseUrl + requestUrls.queryGroupChatMessageList,
      method: 'post',
      data: query,
    });
  },
  getOrderInfoBynum(query) {
    return request({
      url: baseUrl + requestUrls.getOrderInfoBynum,
      method: 'post',
      data: query,
    });
  },
  queryPdfImg(query) {
    return request({
      url: baseUrl + requestUrls.queryPdfImg,
      method: 'post',
      data: query,
    });
  },
  // 导出问诊订单
  exportConsultationOrder(query) {
    return request1({
      method: 'post',
      url: baseUrl + requestUrls.exportConsultationOrder,
      data: query,
      responseType: 'blob',
    });
  },
};

const getters = {
  sidebar: (state) => state.app.sidebar,
  // language: (state) => state.app.language,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.public.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,

  orderDetail: (state) => state.order.orderDetail,
  orderList: (state) => state.order.orderList,
  orderStatusAndCount: (state) => state.order.orderStatusAndCount,
  refuseDialogVisible: (state) => state.order.refuseDialogVisible,
  orderStatusList: (state) => state.order.orderStatusList,
  pageInfo: (state) => state.order.pageInfo,

  newRoles: (state) => state.public.newRoles,
  rsaPublicKey: (state) => state.public.rsaPublicKey,
  aesPrivateKey: (state) => state.public.aesPrivateKey,
  hidden(state) {
    // eslint-disable-next-line no-unused-vars
    const temp = state.current;
    if (typeof document.hasFocus !== 'function') {
      return document.hidden;
    }
    return !document.hasFocus();
  },
};
export default getters;

import api from '@/api/basicDictionary';
const state = {
  doctorList: [],
  departmentList: [],
  occupationList: [],
  professionalList: [],
  allDepartmentList: [],
  allOrganList: [],
  consultTypeAllList: [],
};

const mutations = {
  getOrganList: (state, data) => {
    state.doctorList = data;
  },
  getDepartmentList: (state, data) => {
    state.departmentList = data;
  },
  getOccupationList: (state, data) => {
    state.occupationList = data;
  },
  getAllDepartmentList: (state, data) => {
    state.allDepartmentList = data;
  },
  getAllOrganList: (state, data) => {
    state.allOrganList = data;
  },
  getProfessionalList: (state, data) => {
    state.professionalList = data;
  },
  getConsultTypeList: (state, data) => {
    state.consultTypeAllList = data;
  },
};

const actions = {
  organPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .organPageQuery(product)
        .then((response) => {
          commit('getOrganList', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  organEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .organEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  organQueryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .queryList(product)
        .then((response) => {
          commit('getAllOrganList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  departmentPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .departmentPageQuery(product)
        .then((response) => {
          commit('getDepartmentList', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deptEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .deptEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deptQueryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .deptQueryList(product)
        .then((response) => {
          console.log(response.data);
          commit('getAllDepartmentList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationTypePageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationTypePageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationTypeEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationTypeEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 问诊列表查询
  consultationTypeList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationTypeList(product)
        .then((response) => {
          commit('getConsultTypeList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  occupationPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .occupationPageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  occupationEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .occupationEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  occupationQueryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .occupationQueryList(product)
        .then((response) => {
          commit('getOccupationList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  professionalPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .professionalPageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  professionalEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .professionalEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  professionalQueryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .professionalQueryList(product)
        .then((response) => {
          commit('getProfessionalList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  drugEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .drugEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  drugPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .drugPageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  drugDisable({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .drugDisable(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  drugEnable({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .drugEnable(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  importMedical({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .importMedical(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dicQueryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .dicQueryList(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

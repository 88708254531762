import { queryConsultationOrderDetail } from '@/views/modules/video/api/doctor';
const patientModules = {
  state: {
    custom: {
      answerStatus: false, //医生是否回复，第一次回复需要调取/consultation-order/answer接口
      //图文问诊和视频问诊的患者信息
      appointment: {
        patientName: '',
        patientPhoto: '',
      },
      //健康咨询的患者信息
      resource: { patientName: '', patientPhoto: '' },
      serverStartTime: null, //服务开始时间 (视频和图文)
      createName: null, //健康咨询
      summaryDetail: {},
      prescriptionDetailList: [],
      consultationStatus: '',
      doctorId: '',
    },
    //当前操作的订单
    handelItem: {
      orderNo: '',
      consultationType: '',
      consultationOrderNo: '',
      orderStatus: '',
      thirdPartyOrderNo: '',
    },
  },

  getters: {
    custom: (state) => state.custom,
    handelItem: (state) => state.handelItem,
  },
  actions: {
    async updateCustom(context, item) {
      const {
        orderNo,
        consultationType,
        consultationOrderNo,
        orderStatus,
        reset,
      } = item || context.state.handelItem;

      if (reset) {
        context.commit('UPDATE_CUSTOM', {
          answerStatus: false, //医生是否回复，第一次回复需要调取/consultation-order/answer接口
          //图文问诊和视频问诊的患者信息
          appointment: {
            patientName: '',
            patientPhoto: '',
          },
          //健康咨询的患者信息
          resource: { patientName: '', patientPhoto: '' },
          serverStartTime: null, //服务开始时间 (视频和图文)
          createName: null, //健康咨询
          summaryDetail: {},
          prescriptionDetailList: [],
          doctorId: '',
        });
        return;
      }
      const res = await queryConsultationOrderDetail({
        orderNo,
      });
      if (!res.data.data) return;
      const {
        openPreConsultationStatus,
        appointment,
        answerStatus,
        resource,
        serverStartTime,
        createTime,
        consultationStatus,
        doctor,
        doctorId,
      } = res.data.data.orderDetail;
      const { summaryDetail, prescriptionDetailList } = res.data.data;

      context.commit('UPDATE_CUSTOM', {
        orderNo,
        openPreConsultationStatus,
        appointment,
        consultationOrderNo,
        consultationType,
        answerStatus, //医生是否回复，第一次回复需要调取/consultation-order/answer接口
        resource,
        orderStatus,
        serverStartTime,
        summaryDetail,
        prescriptionDetailList,
        createTime,
        consultationStatus,
        doctor,
        doctorId,
      });
    },
  },

  mutations: {
    UPDATE_CUSTOM(state, custom) {
      state.custom = custom;
    },

    UPDATE_HANDEL_ITEM(state, data) {
      state.handelItem = data;
    },
  },
};
export default patientModules;

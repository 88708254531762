const requestUrls = {
  pageQueryResource: '/doctor-health-consultation/pageQueryResource', // 分页查询健康咨询列表
  queryDetail: '/doctor-health-consultation/queryDetail', // 查询健康咨询订单详情
  prescriptionDetail: '/doctor-prescription/queryDetail', // 查询处方详情
  cancelResource: '/health-consultation/cancelResource', // 取消健康咨询订单
  prescriptionPageQuery: '/doctor-prescription/pageQuery', // 分页查询处方订单
  prescriptionDetailById: '/doctor-prescription/queryDetailById', // 根据id查询处方详情
  // getSignQRCode:'/truest/getSignQRCode', // 请求签名二维码页面接口,返回string为html
  auditSignConfirm: '/doctor-prescription/auditSignConfirm', // 审方签名确认
  prescriptionAudit: '/doctor-prescription/audit', // 审核处方
  trustSignature: '/doctor-prescription/trustSignature', // 医网信签章
  distributionPageQuery: '/distribution/pageQuery', // 处方流转管理列表
  distributionDetail: '/distribution/queryDetail', // 处方流转详情
  furtherConsultationDetail: '/api/furtherConsultation/queryDetail', // 查询复诊订单详情
  queryGroupChatMessageList:
    '/api/furtherConsultation/queryGroupChatMessageList', // 查询群聊历史信息
  getOrderInfoBynum: '/doctor-consultation-order/queryDetail', // 根据订单号查询详情
  queryPdfImg: '/doctor-consultation-summary/queryPdfImg', // 查看电子病历PDF
  exportConsultationOrder: '/doctor-consultation-order/export', // 导出问诊订单
};
export default requestUrls;

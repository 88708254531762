export default {
  state: {
    imInfo: {
      appid: '',
      roomId: '',
      userID: '',
      userSig: '',
      uuid: '',
    },
  },
  mutations: {
    UPDATE_IMINFO(state, payload) {
      console.log(payload, 'UPDATE_IMINFO');
      state.imInfo = payload;
    },
  },
  actions: {
    updateIminfo(context, data) {
      console.log(data, 'updateIminfo');
      context.commit('UPDATE_IMINFO', data);
    },
  },
  getters: {
    userID(state) {
      return state.imInfo.userID;
    },
  },
};

const requestUrls = {
  organPageQuery: '/doctor-organ/pageQuery', // 分页机构列表
  organEdit: '/doctor-organ/edit', // 团队编辑
  queryList: '/doctor-organ/queryList', // 机构列表查询
  departmentPageQuery: '/dept/pageQuery', // 科室分页查询
  deptEdit: '/doctor-dept/edit', // 科室编辑、新增
  deptDelete: '/doctor-dept/delete', // 科室删除
  deptQueryList: '/doctor-dept/queryList', // 科室列表查询
  consultationTypePageQuery: '/doctor-consultation-type/pageQuery', // 问诊类型列表分页查询
  consultationTypeList: '/doctor-consultation-type/list', // 问诊类型查询所有类型
  consultationTypeEdit: '/doctor-consultation-type/edit', // 问诊类型编辑
  occupationPageQuery: '/doctor-occupation/pageQuery', // 职业分页查询
  occupationEdit: '/doctor-occupation/edit', // 职业编辑
  occupationQueryList: '/doctor-occupation/queryList', // 列表查询职业
  professionalPageQuery: '/doctor-professional-title/pageQuery', // 职称分页查询
  professionalEdit: '/doctor-professional-title/edit', // 职称编辑
  professionalQueryList: '/doctor-professional-title/queryList', // 列表查询职称
  drugEdit: '/doctor-medical/edit', // 药品编辑
  drugPageQuery: '/doctor-medical/pageQuery', // 分页查询药品列表
  drugDisable: '/doctor-medical/disable', // 禁用药品
  drugEnable: '/doctor-medical/enable', // 启用药品
  importMedical: '/doctor-medical/importMedical', // 导入药品字典

  // 基础字典维护
  editDic: '/doctor-dic-base/edit', // 字典编辑
  queryDicTypeList: '/doctor-dic-base/queryDicTypeList', // 查询字典分类列表
  pageQueryDic: '/doctor-dic-base/pageQuery', // 分页查询字典
  deleteDic: '/doctor-dic-base/delete', // 删除字典
  importBase: '/doctor-dic-base/importBase', // 导入基础字典
  dicQueryList: '/doctor-dic-base/queryList', // 查询基础字典列表
};
export default requestUrls;

import api from '@/api/doctorTeam';
// console.log(pageQuery, edit);
const state = {
  teamList: [],
  allDoctorList: [],
};

const mutations = {
  getTeamList: (state, data) => {
    state.teamList = data;
  },
  getAllDoctorList: (state, data) => {
    state.allDoctorList = data;
  },
};

const actions = {
  pageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .pageQuery(product)
        .then((response) => {
          commit('getTeamList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  edit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .edit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .queryList(product)
        .then((response) => {
          commit('getAllDoctorList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

const requestUrls = {
  querySpecList: '/doctor-dic-spec/querySpecList', // 查询处方审核规范列表
  editSpec: '/doctor-dic-spec/editSpec', // 处方审核规范类型字典编辑
  deleteSpec: '/doctor-dic-spec/deleteSpec', // 删除处方审核规范字典

  querySpecTypeList: '/doctor-dic-spec/querySpecTypeList', // 查询处方类型
  editSpecType: '/doctor-dic-spec/editSpecType', // 编辑处方类型
  deleteSpecType: '/doctor-dic-spec/deleteSpecType', // 删除处方类型

  querySpecDetailList: '/doctor-dic-spec/querySpecDetailList', // 查询处方审核规范明细列表
  editSpecDetail: '/doctor-dic-spec/editSpecDetail', // 处方审核规范明细编辑
  deleteSpecDetail: '/doctor-dic-spec/deleteSpecDetail', // 删除审方明细
  querySpecDetailListByCode: '/doctor-dic-spec/querySpecDetailListByCode', //
  specMoveUpOrDown: '/doctor-dic-spec/specMoveUpOrDown', // 处方审核规范上移下移
  specTypeMoveUpOrDown: '/doctor-dic-spec/specTypeMoveUpOrDown', // 处方审核规范类型上移下移
  specDetailMoveUpOrDown: '/doctor-dic-spec/specDetailMoveUpOrDown', // 处方审核规范明细上移下移
};
export default requestUrls;

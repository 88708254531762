import medicalInsuranceApi from './privacyPolicy';
import doctorManageApi from './doctorManage';
import orderManageApi from './orderManage';
import basicDictionaryApi from './basicDictionary';
import specConfigApi from './prescriptionAuditConfig';
import hainanOrganApi from './hainanOrgan';
import privacyPolicy from './privacyPolicy';
import rosterManageApi from './rosterManage';
import medicalHistoryApi from './medicalHistory';
import followUpReportApi from './followUpReport';
import followUpTemplateApi from './followUpTemplate';
import followUpProjectApi from './followUpProject';
export default {
  ...medicalInsuranceApi,
  ...doctorManageApi,
  ...basicDictionaryApi,
  ...orderManageApi,
  ...specConfigApi,
  ...hainanOrganApi,
  ...privacyPolicy,
  ...rosterManageApi,
  ...medicalHistoryApi,
  ...followUpReportApi,
  ...followUpTemplateApi,
  ...followUpProjectApi,
};

import request from '@/utils/requestDoctor';
import requestUrls from './url';
import axios from 'axios';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 垫付订单列表
  pageQuery(query) {
    return request({
      url: baseUrl + requestUrls.pageQuery,
      method: 'post',
      data: query,
    });
  },
  // 团队编辑
  edit(query) {
    return request({
      url: baseUrl + requestUrls.edit,
      method: 'post',
      data: query,
    });
  },
  // 查询医生团队列表
  queryList(query) {
    return request({
      url: baseUrl + requestUrls.queryList,
      method: 'post',
      data: query,
    });
  },
  /* 上传接口 ---------------------------------------------------------------------*/
  // 导入清单
  importClaimOrdersExcel(query) {
    return axios.post(requestUrls.importClaimOrdersExcel, query).then((res) => {
      return res.data;
    });
  },
};

/*
 * @Descripttion:
 * @Author: machao
 * @Date: 2022-10-20 16:07:12
 * @LastEditors: machao
 * @LastEditTime: 2022-10-26 16:55:25
 */
const requestUrls = {
  doctorPageQuery: '/doctor-info/pageQuery', // 分页查询医生列表
  edit: '/doctor-info/edit', // 团队编辑
  freeze: '/doctor-info/freeze', // 冻结
  offline: '/doctor-info/offline', // 下线
  online: '/doctor-info/online', // 上线
  audit: '/doctor-info/audit', // 审核
  unfreeze: '/doctor-info/unfreeze', // 解冻
  pushTrust: '/doctor-info/pushTrust', // 同步医网信
  queryById: '/doctor-info/queryById', // 根据医生id查询医生详情
  importWhite: '/doctor-white/importWhite', // 导入白名单
  whitePageQuery: '/doctor-white/pageQuery', // 分页查询白名单
  remind: '/doctor-white/remind', // 提醒
  whiteDelete: '/doctor-white/delete', // 白名单删除
  consultationPageQuery: '/doctor-set-consultation/pageQuery', // 分页查询问诊配置
  consultationGet: '/doctor-set-consultation/get', // 查询问诊配置详情
  consultationEdit: '/doctor-set-consultation/edit', // 问诊设置编辑
  orderPageQuery: '/doctor-consultation-order/pageQuery', // 问诊订单分页查询
  consultationOrderCancel: '/doctor-consultation-order/cancel', // 退诊
  countByStatus: '/doctor-consultation-order/countByStatus', // 根据状态统计数据
  // v1.1
  cancelOrder: '/doctor-text-consultation/cancelOrder', // 取消图文问诊
  textQueryDetail: '/doctor-text-consultation/queryDetail', // 查询图文问诊详情
  queryConsultationTalkHistoryList:
    '/doctor-consultation-order/queryConsultationTalkHistoryList', // 查询图文问诊订单历史记录
  summaryDetail: '/doctor-consultation-summary/queryDetail', // 问诊小结
  // 药师账号绑定
  pharmacistPageQuery: '/doctor-pharmacist-bind/pageQuery', // 分页查询绑定记录
  pharmacistAddBind: '/doctor-pharmacist-bind/addBind', // 新增药师绑定
  pharmacistGetUserInfo: '/doctor-pharmacist-bind/getUserInfo', // 获取管理后台用户信息
  pharmacistQueryDoctor: '/doctor-pharmacist-bind/queryDoctor', // 查询医生信息
  pharmacistUnBind: '/doctor-pharmacist-bind/unBind', // 解除绑定
  pushSupervisePlat: '/doctor-info/pushSupervisePlat', // 同步监管部门

  // 监管机构列表
  regulatoryOrganList: '/doctor-regulatory-organ/queryList',
  videoQueryDetail: '/video-consultation/queryDetail', // 视频问诊详情
  findListByDoctorId: '/doctor-group/findListByDoctorId', // 根据医生id查询医生所在小组列表及成员信息(组长、普通成员的组，都返回)
  addDoctor: '/doctor-group/addDoctor', // 添加医生
  removeDoctor: '/doctor-group/removeDoctor', // 移除医生
  queryFurtherConsultationDoctorList:
    '/doctor-info/queryFurtherConsultationDoctorList', // 查询专病医生分页
  queryWhiteConfigStatus: '/doctor-white/queryConfigStatus', // 查询白名单配置开关状态
  operateWhiteStatus: '/doctor-white/operationStatus', // 操作白名单开关状态
  materialPageQuery: '/doctor-material/pageQuery', // 物料分页查询
  materialDetail: '/doctor-material/queryDetail', // 物料详情
  materialAdd: '/doctor-material/add', // 物料新增
  materialEdit: '/doctor-material/edit', // 物料修改
  getMaterialQrCodeUrl: '/doctor-material/getMaterialQrCodeUrl', // 获取物料url
  furtherConsultationDoctorList: '/api/furtherConsultation/pageQueryDoctor', // 分页查询复诊医生列表

  // 扫码统计
  materialCountQuery: '/doctor-material/materialCountQuery',
  queryMaterialCheckInLog: '/doctor-material/queryMaterialCheckInLog',
  exportCheckInLog: '/doctor-material/exportCheckInLog',

  // IM咨询小组
  queryPageByCondition: '/patientDoctorGroup/queryPageByCondition',
  queryGroupDetail: '/patientDoctorGroup/queryGroupDetail',
  setImGroupConfigure: '/patientDoctorGroup/setImGroupConfigure',
  removeDoctorMember: '/patientDoctorGroup/removeDoctorMember',
  addDoctorMember: '/patientDoctorGroup/addDoctorMember',
};
export default requestUrls;

<template>
  <div>
    <transition name="el-fade-in-linear">
      <router-view />
    </transition>
    <audio :src="water" id="eventAudio"></audio>
  </div>
</template>

<style>
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<script>
import { messages } from '@/i18n';
import water from '@/assets/water.mp3';
function getTimeDifference(startDate, targetDay, targetHour, targetMinute) {
  const currentDate = new Date(startDate);

  // 获取下个月的同一天
  let nextMonthDate = new Date(currentDate);
  nextMonthDate.setMonth(currentDate.getMonth() + 1);

  // 设置时和分
  nextMonthDate.setHours(targetHour, targetMinute, 0, 0);

  // 处理下个月不存在指定日期的情况，顺延到下个月的第一天
  while (nextMonthDate.getDate() !== 1) {
    nextMonthDate.setDate(nextMonthDate.getDate() + 1);
  }

  // 设置目标日期
  nextMonthDate.setDate(targetDay);

  // 计算时间差
  const timeDifference = nextMonthDate - currentDate;

  // 将时间差转换为天、小时和分钟
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  return {
    days,
    hours,
    minutes,
  };
}

// 示例：获取距离下个月的 30 号 10:10 的时间间隔
const startDate = new Date('2024/1/14 10:09');

const targetDay = 30;
const targetHour = 10;
const targetMinute = 10;

const timeDifference = getTimeDifference(
  startDate,
  targetDay,
  targetHour,
  targetMinute
);

console.log(timeDifference, 'timeDifference');

export default {
  data() {
    return {
      water,
    };
  },
  watch: {
    '$i18n.locale': 'i18nHandle',
  },
  created() {
    this.i18nHandle(this.$i18n.locale);
  },
  methods: {
    i18nHandle(val, oldVal) {
      sessionStorage.setItem('language', val);
      document.querySelector('html').setAttribute('lang', val);
      document.title = messages[val].brand.lg;
      // 非登录页面，切换语言刷新页面
      if (this.$route.name !== 'login' && oldVal) {
        window.location.reload();
      }
    },
  },
};
</script>

import request from '@/utils/requestDoctor';
import requestUrls from './url';
import axios from 'axios';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';
export default {
  // 分页查询机构列表
  organPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.organPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 机构编辑、新增
  organEdit(query) {
    return request({
      url: baseUrl + requestUrls.organEdit,
      method: 'post',
      data: query,
    });
  },
  // 查询机构列表
  queryList(query) {
    return request({
      url: baseUrl + requestUrls.queryList,
      method: 'post',
      data: query,
    });
  },
  // 科室分页查询
  departmentPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.departmentPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 科室编辑、新增
  deptEdit(query) {
    return request({
      url: baseUrl + requestUrls.deptEdit,
      method: 'post',
      data: query,
    });
  },
  // 科室删除
  deptDelete(query) {
    return request({
      url: baseUrl + requestUrls.deptDelete,
      method: 'post',
      data: query,
    });
  },
  // 查询科室列表
  deptQueryList(query) {
    return request({
      url: baseUrl + requestUrls.deptQueryList,
      method: 'post',
      data: query,
    });
  },
  // 分页查询问诊类型列表
  consultationTypePageQuery(query) {
    return request({
      url: baseUrl + requestUrls.consultationTypePageQuery,
      method: 'post',
      data: query,
    });
  },
  // 问诊类型编辑
  consultationTypeEdit(query) {
    return request({
      url: baseUrl + requestUrls.consultationTypeEdit,
      method: 'post',
      data: query,
    });
  },
  // 职业分页查询
  occupationPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.occupationPageQuery,
      method: 'post',
      data: query,
    });
  },
  occupationEdit(query) {
    return request({
      url: baseUrl + requestUrls.occupationEdit,
      method: 'post',
      data: query,
    });
  },
  occupationQueryList(query) {
    return request({
      url: baseUrl + requestUrls.occupationQueryList,
      method: 'post',
      data: query,
    });
  },
  professionalPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.professionalPageQuery,
      method: 'post',
      data: query,
    });
  },
  professionalEdit(query) {
    return request({
      url: baseUrl + requestUrls.professionalEdit,
      method: 'post',
      data: query,
    });
  },
  professionalQueryList(query) {
    return request({
      url: baseUrl + requestUrls.professionalQueryList,
      method: 'post',
      data: query,
    });
  },
  consultationTypeList(query) {
    return request({
      url: baseUrl + requestUrls.consultationTypeList,
      method: 'post',
      data: query,
    });
  },
  // 药品编辑
  drugEdit(query) {
    return request({
      url: baseUrl + requestUrls.drugEdit,
      method: 'post',
      data: query,
    });
  },
  // 药品分页查询
  drugPageQuery(query) {
    return request({
      url: baseUrl + requestUrls.drugPageQuery,
      method: 'post',
      data: query,
    });
  },
  // 药品禁用
  drugDisable(query) {
    return request({
      url: baseUrl + requestUrls.drugDisable,
      method: 'post',
      data: query,
    });
  },
  // 药品启用
  drugEnable(query) {
    return request({
      url: baseUrl + requestUrls.drugEnable,
      method: 'post',
      data: query,
    });
  },

  // 基础字典维护
  editDic(query) {
    return request({
      url: baseUrl + requestUrls.editDic,
      method: 'post',
      data: query,
    });
  },
  queryDicTypeList(query) {
    return request({
      url: baseUrl + requestUrls.queryDicTypeList,
      method: 'post',
      data: query,
    });
  },
  pageQueryDic(query) {
    return request({
      url: baseUrl + requestUrls.pageQueryDic,
      method: 'post',
      data: query,
    });
  },
  deleteDic(query) {
    return request({
      url: baseUrl + requestUrls.deleteDic,
      method: 'post',
      data: query,
    });
  },
  // 查询基础字典列表
  dicQueryList(query) {
    return request({
      url: baseUrl + requestUrls.dicQueryList,
      method: 'post',
      data: query,
    });
  },
  //
  // // 导入药品字典
  importMedical(query) {
    return request({
      url: baseUrl + requestUrls.importMedical,
      method: 'post',
      data: query,
      // headers: {
      //   token: sessionStorage.getItem('token'),
      // },
    });
  },
  /* 上传接口 ---------------------------------------------------------------------*/
  // 导入药品字典
  // importMedical(query) {
  //   return axios
  //     .post(baseUrl + requestUrls.importMedical, query,)
  //     .then((res) => {
  //       return res.data;
  //     });
  // },
  // 导入基础字典
  importBase(query) {
    return axios.post(baseUrl + requestUrls.importBase, query).then((res) => {
      return res.data;
    });
  },
};

const requestUrls = {
  /* ---------------------------------- public sso ---------------------------------- */
  manniuUserInfo: '/operation-gateway/oauth2/getUserInfo', // 姚俊
  // manniuLogout: '/houtai/maniu/oauth/loginout',
  manniuLogout: '/operation-gateway/oauth2/getLoginOutUrl',

  /* ----------------------------------- RSA 公钥 ---------------------------------- */
  cipher: process.env.VUE_APP_CIPHER
}
export default requestUrls


import api from '@/api/doctorManage';
const state = {
  doctorList: [],
  doctorWhiteList: [],
  firstPracticeOrganList: [], // 监管机构列表
};

const mutations = {
  getDoctorList: (state, data) => {
    state.teamList = data;
  },
  getDoctorWhiteList: (state, data) => {
    state.doctorWhiteList = data;
  },
  getPracticeOrganList: (state, data) => {
    state.firstPracticeOrganList = data;
  },
};

const actions = {
  doctorPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .doctorPageQuery(product)
        .then((response) => {
          commit('getDoctorList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  edit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .edit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  online({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .online(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  offline({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .offline(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  audit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .audit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unfreeze({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .unfreeze(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  freeze({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .freeze(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryById({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .queryById(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  whitePageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .whitePageQuery(product)
        .then((response) => {
          commit('getDoctorWhiteList', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  remind({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .remind(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationPageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationGet({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationGet(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationEdit({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationEdit(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  orderPageQuery({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .orderPageQuery(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  consultationOrderCancel({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .consultationOrderCancel(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  countByStatus({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .countByStatus(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // v1.1
  // 取消图文问诊
  cancelOrder({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .cancelOrder(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 查询图文问诊详情
  textQueryDetail({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .textQueryDetail(product)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //  监管机构列表
  regulatoryOrganList({ commit }, product) {
    return new Promise((resolve, reject) => {
      api
        .regulatoryOrganList(product)
        .then((response) => {
          commit('getPracticeOrganList', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

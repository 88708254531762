import request from '@/utils/requestDoctor';
import requestUrls from './url';
// import axios from 'axios';
const baseUrl = 'https://scusapi.shanci.tech';
// const baseUrl = '';

// 图片上传
export function getImgSign(query) {
  return request({
    url: baseUrl + requestUrls.getImgSign,
    method: 'post',
    data: query,
  });
}

// // oss上传文件
// export async function ossUploadApi(file) {
//   // 从服务器获取oss配置信息
//   const res = await getImgSign();
//   if (res.code !== 200) return;
//   const ret = res.data;
//   // 构造form表单
//   const form = new FormData();
//   const index = file.name.lastIndexOf('.');
//   const suffix = file.name.substring(index);
//   const key = `${ret.dir}/pic_${new Date().getTime()}${suffix}`;
//   form.append('signature', `${ret.signature}`);
//   form.append('OSSAccessKeyId', `${ret.accessid}`);
//   form.append('success_action_status', '200');
//   form.append('policy', `${ret.policy}`);
//   form.append('key', key);
//   form.append('file', file);
//   // 不能携带浏览器信息
//   return axios.post(ret.host, form, { withCredentials: false }).then(() => {
//     return {
//       url: `${ret.host}/${key}`,
//       key,
//       name: file.name,
//     };
//   });
// }
